@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.Maps{
    width: 100%;
    height: calc(100vh - 72px);
}
.MarkerPin, .BusPin{
    cursor: pointer;
    stroke-width: 5px;
    stroke:black;
}


.CloseBtn{
    padding: 10px;
    cursor: pointer;
    color: #fff;
}

.Filter{
    z-index: 999;
    position: absolute;
    bottom: 112px;
    right: 10px;
    background: #fff;
    font-size: 18px;
    color:#565656;
    cursor: pointer;
    border-radius:3px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    padding: 8.5px 11px;
}
.KPIToggle{
    z-index: 99999;
    position:absolute;
    top:25px;
    right: 50px;
    cursor: pointer;
}
.KPIToggleContainer label{
    color: #fff;
    z-index: 999;
    position:absolute;
    top:26px;
    font-weight: 500;
    font-size: .9em;
    right: 20px;
}
.FilterDrop{
    background: rgba(18, 90, 161, 0.98);
    color: #fff;
    margin: 0 330px;
    top: 12px;
    padding: 4px 10px;
    position: absolute;
    left: 0;
    min-width: 40px;
    display: flex;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    border-radius: 3px;
}
/* KPI TOGGLE */
.KPIContainer{
    position: absolute;
    z-index: 99;
    display: flex;
    flex-direction: column;
    right: 50px;
    top: 79px;
}
.Card{
    color: #fff;
    margin: 5px;
    font-size: .9em;
}
.CardHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.2em;

}
.CardHeader p{
    font-family: 'Open Sans';
    font-weight: 700;
    margin: 0 10px 0 0px;
}
.Digit{
    margin: 0 0 5px 0;
    font-size: 1.2em;
    font-weight: 700;
}
.Info{
    display: flex;
    flex-direction: row;
}
.Info p{
    font-size: .8em;
    margin: 0;
    font-family: 'Open Sans';
    font-weight: 700;
}
/* END OF KPI TOGGLE */
/* MARKER SIDEBAR */
.MarkerSideBar{
    background-color: #000;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    z-index: 999;
    position:absolute; 
    border-radius: 3px; 
    right: 0;
    width: 450px;
    margin: 10px 55px;
}
.MarkerSideBarHeader{
    margin: 10px 0 10px 10px;
} 
.MarkerSideBarHeader h2{
    margin: 0 0 0 0;
    font-size: 1.5em;
    font-weight: normal;
    color: #fff;
}
.MarkerSideBarHeader p{
    margin: 0;
    font-size: .7em;
    color: #c0c0c0;
    font-weight: 600;
}
.MarkerSBHeaderBG{
    border-radius: 3px 3px 0 0;
    margin: 0;
    padding-bottom: 5px;
    padding: 0px 10px 10px 10px;
    background-color: #28313b;
}
.BusETA{
    background-color: #000;
    padding: 0px 40px;
}
.ETATableContainer{
    background-color: #000;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: auto;
}
.BusETA p{
    font-size: .85em;
    padding: 15px 0 15px 0px;
    font-weight: 500;
    color: #fff;
}
.Active{
    margin: 2px 0 0 5px;
    padding: 0;
}
.Diagnostic{
    padding: 0 0 10px 10px;
    display: flex;
    flex-direction: row;
}
.Diagnostic p{
    color: #fff;
    font-weight: 500;
    font-size: .7em;
    margin: 0;
}
.DiagnosticStatus{
    margin-right: 10px;
    display: flex;
    background: #565656;
    padding: 5px 10px;
    border-radius: 3px;
}
.RSSI{
    padding:0 0 0 7px;
    color: #0f0;
}
.ETARow{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid transparent;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    /* background: #fff; */
    /* margin-bottom: 10px; */
    /* border-radius: 4px; */
}
.ETACell, .ETACellHeader{
    padding: 10px 0px;
    text-transform: uppercase;
    font-family: 'Press Start 2P', cursive;
    font-size: .75em;
    width: 200px;
    color: #F7B900 !important;

}
.ETACellValue{
    overflow: auto;
    height: 295px;
    font-size: .8em;
}
.ETARowHeader{
    display: flex;
    justify-content: space-between;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    border-bottom: 1px solid transparent;
    background:#000;
    font-weight: 500;
    /* border-radius: 4px;   */
    /* margin-bottom: 10px; */
}
/* END OF MARKER SIDEBAR */
.BusPinClose{
    cursor: pointer;
    margin-left: 5px;
}
.BusPin{
    font-size: 1.3em;
}
.BusPinInfo{
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    background: #fff;
    padding: 10px;
    border-radius: 1px;
    width: 220px;
    position: absolute;
    margin-top: 15px;
    margin-left: -105px;
    z-index: 999;
}
.BusPinInfo::before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
	left: 9.75em; 
	border: .75rem solid transparent;
	border-top: none;
	border-bottom-color: #fff;
	filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}
.BusNumber{
    text-align: center;
    width: 19px;
    color: #000;
    font-weight: 700;
    background: #fff;
    border-radius: 2px;
    position: absolute;
    font-size: .5em;
    padding: 2px 1px 2px 1px;
    margin: 4px 0 0px 3.5px;
}
.BusDetails{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5px;
}
.BusDetails p{
    margin: 0;
    padding: 5px 0;
    display: inline;
    font-size: 1em;
}
p.BusDetailsRight{
    padding-left: 10px;
    font-weight: 700;
    color: #125AA1;
}
/* MONITOR BUS STOPS */
.BusStopContainer{
    position: absolute;
    z-index: 999;
    background: rgba(18, 90, 161, 0.98);
    font-size: 18px;
    color:#fff;
    cursor: pointer;
    border-radius:3px;
    top:85px;
    margin-left: 10px ;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    width:300px;
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: space-between;
}
.BusStopContainer p{
    margin: 0;
    padding: 7px 17px;
}
.BusStopContainer:hover{
    background: rgba(18, 90, 161, 0.95  );
}
.MonitorCollapse::-webkit-scrollbar-track {
    background: #28313B; 
  }
   
.MonitorCollapse{
    padding:0px 0 10px 10px;
    background-color: #28313B;
    overflow-y: scroll;
    max-height: 70vh;
}
.MonitorSideBarHeader{
    margin: 10px 0 10px 10px;
} 
.MonitorSideBarHeader h2{
    margin: 0 0 0 0;
    font-size: 1.5em;
    font-weight: normal;
}
.MonitorSideBarHeader p{
    margin: 0;
    font-size: .7em;
    color: #808080;
    font-weight: 600;
}
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .control-pane #multisection.control-section, .control-section #custom-filtering {
    width: 80%;
    margin: 0 auto;
    min-width: 185px;
}
.e-bigger .control-pane #multisection.control-section,
.e-bigger .control-section #custom-filtering {
    border-bottom: 0;
}


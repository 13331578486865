
.SideBar{
    top:74;
    height: 100%;
}
.Container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}
.Body{
    height: 100vh;
    padding-top: 20px;
    overflow-x: auto;
}
.Body h2{
    padding-left: 10px;
    font-weight: 500;
}
.e-grid {
    background: #125AA1;
}
.e-grid .e-gridheader {
    border:2px solid green
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
.HeaderWrapper {
    background-color: #125AA1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0px 10px;
    height: 74px;
}
.Logo{
    height: 50px;
    width: 50px;
    padding: 10px ;
}
.Brand{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    color: #ffffff;
    padding-left: 10px;
    flex: 1;
}

@media only screen and (max-width: 620px) {
.Brand{
    font-size: 17px;
}
}
@media only screen and (max-width: 550px) {
    .Brand{
        font-size: 12px;
    }
    }
